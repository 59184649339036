import React from "react";
import { useSelector } from "react-redux";
import BlogArticle from "../Blog/BlogArticle";
import { RootState } from "../store";

export default function BlogShort() {
  const blog = useSelector((state: RootState) => ({
    posts: state.blog.posts?.slice(0, 3),
    header: state.blog.blogBanner?.header,
  }));

  if (!blog.posts.length) {
    return <></>;
  }

  return (
    <section className="blog blog--bg">
      <div className="container flexbox-md">
        <div className="blog__section-col">
          <h2 className="blog__section-header">{blog.header}</h2>
          <BlogArticle
            title={blog.posts[0].title.rendered}
            content={blog.posts[0].excerpt.rendered}
            link={blog.posts[0].id}
            isExcerpt
          />
        </div>

        {blog.posts.slice(1).map((post) => (
          <div key={post.id} className="blog__section-col">
            <BlogArticle
              date={post.date_gmt}
              title={post.title.rendered}
              content={post.excerpt.rendered}
              link={post.id}
              isExcerpt
            />
          </div>
        ))}
      </div>
    </section>
  );
}
