import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export default function Banner() {
  const blogBanner = useSelector((state: RootState) => state.blog.blogBanner);
  return (
    <div
      className="banner"
      style={{
        backgroundImage: `url(${blogBanner?.banner})`,
      }}
    >
      <div className="container container--narrow">
        <h1 className="banner__header">{blogBanner?.header}</h1>
      </div>
    </div>
  );
}
