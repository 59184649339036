import React from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../store";
import Banner from "./Banner";
import { fetchPostsAction, IPosts } from "./Blog.slice";
import BlogArticle from "./BlogArticle";

function BlogPage({ posts, showBtn }: { posts: IPosts[]; showBtn: boolean }) {
  const dispatch = useDispatch();

  return (
    <>
      <Banner />
      <main>
        {posts?.map((post) => (
          <BlogArticle
            key={post.id}
            title={post.title.rendered}
            date={post.date_gmt}
            content={post.content.rendered}
            hash={post.id}
            gallery={post.acf?.gallery}
          />
        ))}
        {showBtn && (
          <div className="text-center mgtb-20">
            <button
              className="btn"
              onClick={() => dispatch(fetchPostsAction())}
            >
              Pokaż więcej
            </button>
          </div>
        )}
      </main>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  posts: state.blog.posts,
  showBtn: state.blog.postsTotal > state.blog.postsOffset,
});

export default connect(mapStateToProps)(BlogPage);
