import React from "react";
import parse from "html-react-parser";
import { HashLink } from "react-router-hash-link";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { IImage } from "../common.interface";

interface IProps {
  title: string;
  content: string;
  link?: string | number;
  hash?: string | number;
  date?: string;
  isExcerpt?: boolean;
  gallery?: {
    full_img: IImage;
  }[];
}

export default function BlogArticle({
  title,
  content,
  link,
  date,
  isExcerpt,
  hash,
  gallery,
}: IProps): React.ReactElement {
  const dateInstance = date && new Date(date);
  const formattedDate =
    dateInstance &&
    `${dateInstance.getDate()}.${
      dateInstance.getMonth() + 1
    }.${dateInstance.getFullYear()}`;

  return (
    <article className="section" id={`${hash}`}>
      {isExcerpt ? (
        <>
          {formattedDate && <div className="blog__date">{formattedDate}</div>}
          <h1 className={`blog__header `}>{title}</h1>
        </>
      ) : (
        <div className="blog__header-bg">
          <div className="container container--narrow">
            {formattedDate && <div className="blog__date">{formattedDate}</div>}
            <h1 className={`text-orange blog__header no-margin`}>{title}</h1>
          </div>
        </div>
      )}

      {isExcerpt ? (
        parse(content)
      ) : (
        <div className="container container--narrow mgtb-30">
          {parse(content)}
          {gallery && (
            <SimpleReactLightbox>
              <SRLWrapper>
                {gallery.map((item, index) => (
                  <a key={index} href={item.full_img.url} data-attribute="SRL">
                    <img
                      src={item.full_img.sizes.medium_large}
                      className="blog__image"
                    />
                  </a>
                ))}
              </SRLWrapper>
            </SimpleReactLightbox>
          )}
        </div>
      )}
      {link && (
        <HashLink to={`/aktualnosci#${link}`} className="text-orange arrows">
          Czytaj więcej
        </HashLink>
      )}
    </article>
  );
}
