import Axios, { AxiosResponse } from "axios";
import { IPosts } from "../components/Blog/Blog.slice";
import { ICategory, IGallery } from "../components/HomePage/Home.slice";

const acfAPI = Axios.create({
    baseURL: `${process.env.REACT_APP_HOST}/wp-json/acf/v3/`
});
const wpApi = Axios.create({
    baseURL: `${process.env.REACT_APP_HOST}/wp-json/wp/v2/`
});

const customApi = Axios.create({
    baseURL: `${process.env.REACT_APP_HOST}/wp-json/custom/`
});


export function fetchPages(): Promise<AxiosResponse<unknown>>{
    return wpApi.get('pages')
}

export function fetchPageContentBySlug(slug: string): Promise<AxiosResponse<{acf: any, content: {rendered: any}}[]>>{
    return wpApi.get(`pages?slug=${slug}`)
}

export function fetchPosts(filters?: string): Promise<AxiosResponse<IPosts[]>>{
    return wpApi.get(`posts${filters || ''}`)
}

export function fetchPostContent(id: number): Promise<AxiosResponse<IPosts>>{
    return wpApi.get(`posts/${id}`)
}

export function fetchGelleries(filters: string): Promise<AxiosResponse<IGallery[]>>{
    return wpApi.get(`gallery${filters}`)
    // const filters = category ? `?categories=${category}` : '';
    // return wpApi.get(`gallery${filters}`)
}
export function fetchGelleryCategories(): Promise<AxiosResponse<ICategory[]>>{
    // 3 na produkcji
    return wpApi.get('categories?parent=3')
}

export function fetchMenu(slug: 'nav-main' | 'nav-footer'): Promise<AxiosResponse<any>>{
    return customApi.get(`menu/${slug}`);
}

export function fetchViewCounter(): Promise<AxiosResponse<number>>{
    return customApi.get('counter');
}

export function fetchLastModifiedDate(): Promise<AxiosResponse<string>>{
    return customApi.get('modified');
}

export function setViewCount(): Promise<AxiosResponse<number>>{
    return customApi.post('counter');
}