import React, { useEffect } from "react";
import { RouteProps, withRouter } from "react-router-dom";

function ScrollTop({ location }: RouteProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <React.Fragment />;
}

export default withRouter(ScrollTop);
