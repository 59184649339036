import React from "react";
import { useSelector } from "react-redux";
import FooterContactItem from "../Layout/FooterContactItem";
import { RootState } from "../store";

interface IProps {
  isFooter?: boolean;
}

export default function ContactDetails({ isFooter }: IProps) {
  const contactDetails = useSelector((state: RootState) => {
    const result: any[] = [];

    state.home.contactContent.contact?.forEach((contact) => {
      if (!contact.sub) {
        result.push({ ...contact });
      }
    });

    state.home.contactContent.contact?.forEach((contact) => {
      if (contact.sub) {
        const r = result.find((c) => c.name === contact.sub);
        if (r) {
          r["sub"] = { ...contact };
        }
      }
    });

    return result;
  });

  return (
    <div className={`contact ${isFooter ? "contact--footer" : ""}`}>
      <div className="container container--narrow ">
        <div className="contact__row flexbox-md flexbox--sbet ">
          {contactDetails?.map((detail) => (
            <FooterContactItem
              key={detail.site}
              header={detail.header}
              logo={{ alt: detail.logo.alt, url: detail.logo.url }}
              name={detail.name}
              content={detail.content}
              site={detail.site}
              fullUrl={detail.fullSite}
              sub={detail.sub}
              isFooter={isFooter}
              // sub={isFooter ? undefined : detail.sub}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
