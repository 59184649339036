import React from "react";

interface IProps {
  src: string;
  alt: string;
}

export default function SponsorsLogo({ src, alt }: IProps) {
  return (
    <div className="sponsors__img-box flexbox">
      <img src={src} alt={alt} className="sponsors__img" />
    </div>
  );
}
