import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchLastModifiedDate, fetchMenu, setViewCount } from "../../API/api";
import { AppThunk } from "../store";

export interface INavItems {
    ID: number;
    guid?: string;
    menu_order?: number;
    object_id?: number;
    title: string;
    url: string;
}

interface ILayoutState {
    navItems: INavItems[],
    displayLoader: boolean;
    lastModifiedDate: string | undefined;
    counter: number;
}

const initialState: ILayoutState = {
    navItems: [{
        ID: 1,
        title: 'Podstawowe informacje',
        url: '/',
    },
    {
        ID: 2,
        title: 'Aktualności',
        url: '/aktualnosci/',
    },
    // {
    //     ID: 3,
    //     title: 'Wizualizacje',
    //     url: '/wizualizacje/',
    // },
    {
        ID: 4,
        title: 'Postęp prac',
        url: '/postep-prac/',
    },
    {
        ID: 5,
        title: 'Galeria',
        url: '/galeria/',
    },
    {
        ID: 6,
        title: 'Kontakt',
        url: '/kontakt/',
    }],
    displayLoader: false,
    lastModifiedDate: undefined,
    counter: 0
} 

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
      addNavItems: (state, action: PayloadAction<INavItems[]>) => {
          state.navItems = action.payload
      },
      toggleLoader: (state, action: PayloadAction<boolean>) => {
        state.displayLoader = action.payload;
      },
      addLastModifiedDate: (state, action: PayloadAction<string>) => {
        state.lastModifiedDate = action.payload;
      },
      addCounter: (state, action: PayloadAction<number>) => {
        state.counter = action.payload;
      },
    }
})

export const fetchNavItemsAction = (slug: 'nav-main' | 'nav-footer'): AppThunk => async dispatch => {
    try {
        const navItems = await fetchMenu(slug);
        dispatch(addNavItems(navItems.data));
    } catch (error) {
        console.error((error));
        
    }
};

export const fetchLastModifiedDateAction = (): AppThunk => async dispatch => {
    try {
        const response = await fetchLastModifiedDate();
        dispatch(addLastModifiedDate(response.data));
    } catch (error) {
        console.error((error));
        
    }
};

export const setViewCountAction = (): AppThunk => async dispatch => {
    try {
        const response = await setViewCount();
        dispatch(addCounter(response.data));
    } catch (error) {
        console.error((error));
    }
}


  
export const { addNavItems, toggleLoader, addLastModifiedDate, addCounter } = layoutSlice.actions

export default layoutSlice.reducer;