import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPageContentBySlug, fetchPosts } from "../../API/api";
import { IImage } from "../common.interface";
import { AppThunk } from "../store";

const POST_PAGINATION = 5;

export interface IPosts {
  id: number;
  title: { rendered: string };
  date_gmt: string;
  content: { rendered: string };
  excerpt: { rendered: string };
  acf?: {
    gallery: {
      full_img: IImage;
    }[];
  };
}
export interface IBlogBanner {
  header: string;
  banner: string;
}

interface IBlogPageState {
  posts: IPosts[];
  postsOffset: number;
  postsTotal: number;
  blogBanner: Partial<IBlogBanner>;
}

const initialState: IBlogPageState = {
  posts: [],
  postsOffset: 0,
  postsTotal: 0,
  blogBanner: {},
};

const BlogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    addPosts: (state, action: PayloadAction<IPosts[]>) => {
      state.posts = state.posts.concat(action.payload);
    },
    addPostsTotal: (state, action: PayloadAction<number>) => {
      state.postsTotal = action.payload;
    },
    increasePostsOffset: (state, action: PayloadAction<number>) => {
      state.postsOffset = state.postsOffset + action.payload;
    },
    addBlogBanner: (state, action: PayloadAction<IBlogBanner>) => {
      state.blogBanner = action.payload;
    },
  },
});

const {
  addPosts,
  addPostsTotal,
  increasePostsOffset,
  addBlogBanner,
} = BlogSlice.actions;

export const fetchPostsAction = (): AppThunk => async (dispatch, getState) => {
  try {
    const { blog } = getState();
    const filters = `?per_page=${POST_PAGINATION}&offset=${blog.postsOffset}`;
    const response = await fetchPosts(filters);
    dispatch(addPostsTotal(Number(response.headers["x-wp-total"])));
    dispatch(increasePostsOffset(POST_PAGINATION));
    dispatch(addPosts(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const fetchBlogContentAction = (): AppThunk => async (dispatch) => {
  try {
    const response = await fetchPageContentBySlug("aktualnosci");
    dispatch(addBlogBanner(response.data[0].acf));
  } catch (error) {
    console.error(error);
  }
};

export default BlogSlice.reducer;
