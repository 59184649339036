import React from "react";
import { useSelector } from "react-redux";
import { fetchPrivacyPolicyAction } from "./HomePage/Home.slice";
import useLoader from "./Layout/UseLoader.hook";
import { RootState } from "./store";
import parse from "html-react-parser";

export default function TextPage() {
  const privacyPolicy = useSelector(
    (state: RootState) => state.home.privacyPolicyContent
  );
  useLoader(fetchPrivacyPolicyAction);
  return (
    <div className="section container container--narrow">
      {privacyPolicy && parse(privacyPolicy)}
    </div>
  );
}
