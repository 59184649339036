import React from "react";
import { connect } from "react-redux";
import { RootState } from "../store";
import { IHomePage } from "./Home.slice";
import parse from "html-react-parser";

function Banner({
  header,
  contractNumber,
  slider,
  contractInformation,
  excerpt,
}: Partial<IHomePage>) {
  return (
    <div
      className="banner banner--main flexbox"
      style={{
        backgroundImage: `url(${slider})`,
      }}
    >
      <div className="container container--narrow banner__content-box">
        <div className="text-center">
          {contractNumber && (
            <strong className="text-white">{contractNumber}</strong>
          )}
          <h1 className="text-white">{header}</h1>
          {excerpt && <p className="text-white banner__subheader">{excerpt}</p>}
        </div>
        <div className="line line--orange mgtb-50"></div>
        <div className="flexbox-sm flexbox--sbet banner__content">
          {contractInformation &&
            contractInformation.map((information, index) => (
              <div className="banner__content-col" key={index}>
                {parse(information.content)}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  header: state.home.content?.header,
  contractNumber: state.home.content?.contractNumber,
  contractInformation: state.home.content?.contractInformation,
  slider: state.home.content?.slider,
  excerpt: state.home.content?.excerpt,
});

export default connect(mapStateToProps)(Banner);
