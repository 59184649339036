import React from "react";

export default function Loader({ isLocal = false }: { isLocal?: boolean }) {
  return (
    <div
      className={`loader-wrapper flexbox ${
        isLocal ? "loader-wrapper--local" : ""
      }`}
    >
      <div className="loader"></div>
    </div>
  );
}
