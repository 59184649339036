import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchGelleries,
  fetchGelleryCategories,
  fetchPageContentBySlug,
} from "../../API/api";
import { IImage } from "../common.interface";
import { AppThunk } from "../store";

const GALLERY_PAGINATION = 10;

export interface IHomePage {
  header: string;
  contractNumber: string;
  contractInformation: { content: string }[];
  slider: string;
  excerpt: string;
  contractDescription: string;
  footerBg: string;
}

export interface ISchemaContent {
  content: string;
  schema: {
    header: string;
    fullView: string;
    preview: IImage;
  };
}

export interface IPaginatedGallery {
  gallery: IGallery[];
  postsOffset: number;
  postsTotal: number;
}

export interface IGallery {
  title: { rendered: string };
  acf: {
    gallery?: {
      description: string;
      image: IImage;
    }[];
    videos?: { video: string }[];
  };
}

export interface ICategory {
  parent: number;
  slug: string;
  id: number;
  link: string;
  count: number;
  taxonomy: string;
  name: string;
}

export interface IContact {
  header: string;
  contact: {
    content: string;
    fullSite: string;
    header: string;
    logo: IImage;
    name: string;
    site: string;
    sub?: any;
  }[];
}

export interface IWorkProgress {
  banner: string;
  header: string;
  hProgressbar: {
    name: string;
    value: number;
  };
  circualProgressbar: {
    name: string;
    value: number;
  }[];
}

interface IHomePageState {
  content: Partial<IHomePage>;
  schemaContent: Partial<ISchemaContent>;
  gallery: IPaginatedGallery;
  galleryCategories: ICategory[];
  contactContent: Partial<IContact>;
  workProgressContent: Partial<IWorkProgress>;
  privacyPolicyContent: any;
}

const initialState: IHomePageState = {
  content: {},
  schemaContent: {},
  gallery: {
    gallery: [],
    postsOffset: 0,
    postsTotal: 0,
  },
  galleryCategories: [],
  contactContent: {},
  workProgressContent: {},
  privacyPolicyContent: undefined,
};

const HomeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    addContent: (state, action: PayloadAction<IHomePage>) => {
      state.content = action.payload;
    },
    addSchemaContent: (state, action: PayloadAction<ISchemaContent>) => {
      state.schemaContent = action.payload;
    },
    // addGallery: (state, action: PayloadAction<IGallery[]>) => {
    //     state.gallery = action.payload
    // },
    addGallery: (state, action: PayloadAction<IPaginatedGallery>) => {
      state.gallery = {
        gallery: state.gallery.gallery.concat(action.payload.gallery),
        postsOffset: state.gallery.postsOffset + action.payload.postsOffset,
        postsTotal: action.payload.postsTotal,
      };
    },
    addGalleryCategories: (state, action: PayloadAction<ICategory[]>) => {
      state.galleryCategories = action.payload;
    },
    addContactContent: (state, action: PayloadAction<IContact>) => {
      state.contactContent = action.payload;
    },
    addWorkProgressContent: (state, action: PayloadAction<IWorkProgress>) => {
      state.workProgressContent = action.payload;
    },
    addPrivacyPolicyContent: (state, action: PayloadAction<any>) => {
      state.privacyPolicyContent = action.payload;
    },
    resetGallery: (
      state,
      action: PayloadAction<"gallery" | "aerial" | "video">
    ) => {
      state.gallery = {
        gallery: [],
        postsOffset: 0,
        postsTotal: 0,
      };
    },
  },
});

export const {
  addContent,
  addSchemaContent,
  addGallery,
  addGalleryCategories,
  addContactContent,
  resetGallery,
  addWorkProgressContent,
  addPrivacyPolicyContent,
} = HomeSlice.actions;

export const fetchHomeContentAction = (): AppThunk => async (dispatch) => {
  try {
    const response = await fetchPageContentBySlug("strona-glowna");
    dispatch(addContent(response.data[0].acf));
  } catch (error) {
    console.error(error);
  }
};

export const fetchSchemaContentAction = (): AppThunk => async (dispatch) => {
  try {
    const response = await fetchPageContentBySlug("wizualizacje");
    dispatch(addSchemaContent(response.data[0].acf));
  } catch (error) {
    console.error(error);
  }
};

export const fetchGalleryAction =
  (loadMore: boolean = false): AppThunk =>
  async (dispatch, getState) => {
    const { home } = getState();

    if (home.gallery.gallery.length && !loadMore) {
      return;
    }

    const filters = `?per_page=${GALLERY_PAGINATION}&offset=${home.gallery.postsOffset}`;

    try {
      const response = await fetchGelleries(filters);

      const payload: IPaginatedGallery = {
        gallery: response.data,
        postsOffset: GALLERY_PAGINATION,
        postsTotal: Number(response.headers["x-wp-total"]),
      };

      dispatch(addGallery(payload));
    } catch (error) {
      console.error(error);
    }
  };

// export const fetchGalleryAction = (category?: number): AppThunk => async dispatch => {
//     try {
//         const response = await fetchGelleries(category);
//         dispatch(addGallery(response.data));
//     } catch (error) {
//         console.error(error);
//     }
// }

export const fetchGalleryCategoriesAction =
  (): AppThunk => async (dispatch) => {
    try {
      const { data } = await fetchGelleryCategories();
      dispatch(addGalleryCategories(data));
    } catch (error) {
      console.error(error);
    }
  };

export const fetchContactContentAction = (): AppThunk => async (dispatch) => {
  try {
    const response = await fetchPageContentBySlug("kontakt");
    dispatch(addContactContent(response.data[0].acf));
  } catch (error) {
    console.error(error);
  }
};

export const fetchWorkProgressAction = (): AppThunk => async (dispatch) => {
  try {
    const response = await fetchPageContentBySlug("postep-prac");
    dispatch(addWorkProgressContent(response.data[0].acf));
  } catch (error) {
    console.error(error);
  }
};

export const fetchPrivacyPolicyAction = (): AppThunk => async (dispatch) => {
  try {
    const response = await fetchPageContentBySlug("polityka-prywatnosci");
    dispatch(addPrivacyPolicyContent(response.data[0].content.rendered));
  } catch (error) {
    console.error(error);
  }
};

export default HomeSlice.reducer;
