import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import reducer from './reducers';

// const preloadedState = {
//   layout: {
//       navItems: []
//   },
//   home: {},
//   blog: {}
// }

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  // preloadedState,
})


export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
