import { useEffect, useState } from "react";
import GalleryItem from "./GalleryItem";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleryAction, resetGallery } from "../HomePage/Home.slice";
import { RootState } from "../store";
import parse from "html-react-parser";
import useLoader from "../Layout/UseLoader.hook";

export default function GalleryWithoutCategories() {
  const dispatch = useDispatch();

  const galleries = useSelector((state: RootState) => state.home.gallery);
  const [activeGallery, setActive] = useState(0);
  useLoader(fetchGalleryAction);

  useEffect(() => {
    return () => {
      dispatch(resetGallery("gallery"));
    };
  }, []);

  return (
    <div className="container container--narrow mgtb-50">
      <h1 className="text-orange">Galeria zdjęć</h1>

      {!galleries.gallery.length && "Brak zdjęć"}
      <SimpleReactLightbox>
        {galleries.gallery.map((gallery, index) => (
          <section
            key={gallery.title.rendered}
            className={`gallery mgtb-50 ${
              activeGallery === index ? "gallery--active" : ""
            }`}
          >
            <h2
              className="mgtb-20 gallery__header"
              onClick={setActive.bind(null, index)}
            >
              {parse(gallery.title.rendered)}
            </h2>
            {index === activeGallery && (
              <>
                {gallery.acf?.gallery && (
                  <SRLWrapper>
                    <ul className="gallery__wrapper flexbox flexbox--sbet flexbox--wrap">
                      {gallery.acf.gallery.map((item) => (
                        <GalleryItem
                          key={item.image.id}
                          description={item.description}
                          image={item.image}
                        />
                      ))}
                    </ul>
                  </SRLWrapper>
                )}
                {gallery.acf?.videos && (
                  <ul className="gallery__wrapper flexbox flexbox--sbet flexbox--wrap">
                    {gallery.acf.videos.map((item, i) => (
                      <li
                        className="gallery__item gallery__item--video"
                        key={i}
                      >
                        {parse(item.video)}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </section>
        ))}
      </SimpleReactLightbox>
      {galleries?.postsTotal > galleries?.postsOffset && (
        <div className="text-center mgtb-20">
          <button
            className="btn"
            onClick={() => dispatch(fetchGalleryAction(true))}
          >
            Pokaż więcej
          </button>
        </div>
      )}
    </div>
  );
}
