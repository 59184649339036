import React from "react";
import useLoader from "../Layout/UseLoader.hook";
import AboutContract from "./AboutContract";
import Banner from "./Banner";
import BlogShort from "./BlogShort";
import { fetchHomeContentAction } from "./Home.slice";

export default function HomePage() {
  // useLoader(fetchHomeContentAction);
  return (
    <>
      <Banner />
      <BlogShort />
      <div className="container container--narrow mgtb-60">
        <AboutContract />
      </div>
    </>
  );
}
