import React from "react";
import { useSelector } from "react-redux";
import { fetchSchemaContentAction } from "../HomePage/Home.slice";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { RootState } from "../store";
import useLoader from "../Layout/UseLoader.hook";
export default function SchemePage() {
  const schemaPage = useSelector(
    (state: RootState) => state.home.schemaContent
  );
  // useLoader(fetchSchemaContentAction);

  return (
    <div className="container container--narrow mgtb-50 section">
      <h1 className="text-orange">{schemaPage.schema?.header}</h1>
      <SimpleReactLightbox>
        <SRLWrapper>
          <a href={schemaPage.schema?.fullView} data-attribute="SRL">
            <img
              src={schemaPage.schema?.preview.url}
              alt={schemaPage.schema?.preview.alt}
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>

      {/* <section>
        <h1 className="text-orange">Wizualizacja</h1>
        <img src="/images/map.png" alt="" />
      </section> */}
    </div>
  );
}
