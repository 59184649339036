import React from "react";
import parse from "html-react-parser";

type Sub = {
  header: string;
  logo: { url: string; alt: string };
  name: string;
  content: string;
  site: string;
  fullUrl: string;
};

interface IProps {
  header: string;
  logo: { url: string; alt: string };
  name: string;
  content: string;
  site: string;
  fullUrl: string;
  sub?: Sub;
  isFooter?: boolean;
}

export default function FooterContactItem({
  header,
  logo,
  name,
  content,
  site,
  fullUrl,
  sub,
  isFooter,
}: IProps) {
  return (
    <div>
      <div className="contact__item text-14 flexbox flexbox--col flexbox--sbet flexbox--astart">
        <div>
          <h2 className="contact__header text-16">{header}</h2>
          <img src={logo.url} alt={logo.alt} className="contact__logo" />
          <p className="contact__name">
            <strong>{name}</strong>
          </p>
          {!isFooter && parse(content)}
        </div>
        {!isFooter && fullUrl && (
          <a href={fullUrl} className="text-orange contact__link">
            {site}
          </a>
        )}
      </div>
      {sub && (
        <div className="contact__item text-14 flexbox flexbox--col flexbox--sbet flexbox--astart">
          <div>
            <h2 className="contact__header text-16">{sub.header}</h2>
            <img
              src={sub.logo.url}
              alt={sub.logo.alt}
              className="contact__logo"
            />
            <p>
              <strong>{sub.name}</strong>
            </p>
            {!isFooter && parse(sub.content)}
          </div>
          {!isFooter && sub.fullUrl && (
            <a href={sub.fullUrl} className="text-orange contact__link">
              {sub.site}
            </a>
          )}
        </div>
      )}
    </div>
  );
}
