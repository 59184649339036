import React from "react";
import { useLocation } from "react-router-dom";
import ContactDetails from "../Contact/ContactDetails";
import Credits from "./Credits";
import Navbar from "./Navbar";
import Sponsors from "./Sponsors";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export default function Footer() {
  let location = useLocation();
  const footerBg = useSelector(
    (state: RootState) => state.home.content.footerBg
  );
  return (
    <>
      {!location.pathname.includes("kontakt") && <ContactDetails isFooter />}
      <footer>
        <div
          className="footer"
          style={{
            backgroundImage: `url(${
              footerBg ? footerBg : "/images/footer.png"
            })`,
          }}
        >
          <Navbar isFooter />
        </div>
        <Sponsors center />
        <Credits />
      </footer>
    </>
  );
}
