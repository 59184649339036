import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import ContactDetails from "./ContactDetails";

export default function ContactPage() {
  const contactHeader = useSelector(
    (state: RootState) => state.home.contactContent.header
  );
  return (
    <div className="mgtb-60">
      <div className="container container--narrow">
        <h1 className="text-orange">{contactHeader}</h1>
      </div>

      <ContactDetails />
    </div>
  );
}
