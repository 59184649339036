import React from "react";
import { IImage } from "../common.interface";

interface IProps {
  image: IImage;
  description: string;
}

export default function GalleryItem({ image, description }: IProps) {
  return (
    <li className="gallery__item">
      <figure className="relative gallery__figure">
        <a href={image.url} data-attribute="SRL">
          <img
            src={image.sizes.medium_large}
            alt={description || image.title}
            className="gallery__img"
          />
        </a>
        <figcaption className="gallery__caption">
          {description || image.title}
        </figcaption>
      </figure>
    </li>
  );
}
