import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import HomePage from "./components/HomePage/HomePage";
import BlogPage from "./components/Blog/BlogPage";
import ContactPage from "./components/Contact/ContactPage";
import SchemePage from "./components/Scheme/SchemePage";
import GalleryWithoutCategories from "./components/Gallery/GalleryWithoutCategories";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLastModifiedDateAction,
  fetchNavItemsAction,
  setViewCountAction,
} from "./components/Layout/Layout.slice";
import ScrollToTop from "./components/ScrollTop";
import {
  fetchContactContentAction,
  fetchHomeContentAction,
  fetchSchemaContentAction,
  fetchWorkProgressAction,
} from "./components/HomePage/Home.slice";
import { RootState } from "./components/store";
import Loader from "./components/Layout/Loader";
import useLoader from "./components/Layout/UseLoader.hook";
import WorkProgressPage from "./components/WorkProgress/WorkProgressPage";
import TextPage from "./components/TextPage";
import {
  fetchBlogContentAction,
  fetchPostsAction,
} from "./components/Blog/Blog.slice";

function App() {
  const displayLoader = useSelector(
    (state: RootState) => state.layout.displayLoader
  );

  const loaded = useLoader(
    fetchHomeContentAction,
    fetchPostsAction,
    fetchBlogContentAction,
    fetchSchemaContentAction,
    fetchWorkProgressAction,
    fetchContactContentAction
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNavItemsAction("nav-main"));
    dispatch(setViewCountAction());
    dispatch(fetchLastModifiedDateAction());
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Switch>
        <Route exact path="/aktualnosci">
          <BlogPage />
        </Route>
        <Route exact path="/wizualizacje">
          <SchemePage />
        </Route>
        <Route exact path="/postep-prac">
          <WorkProgressPage />
        </Route>
        <Route exact path="/galeria">
          <GalleryWithoutCategories />
        </Route>
        <Route exact path="/kontakt">
          <ContactPage />
        </Route>
        <Route exact path="/polityka-prywatnosci">
          <TextPage />
        </Route>
        <Route exact path="/">
          <HomePage />
        </Route>
      </Switch>
      <Footer />
      {displayLoader && <Loader />}
    </BrowserRouter>
  );
}

export default App;
