import React from "react";
import { connect } from "react-redux";
import { RootState } from "../store";

function Credits({
  counter,
  lastModifiedDate,
}: {
  counter: number | undefined;
  lastModifiedDate: string | undefined;
}) {
  return (
    <div className="credits">
      <div className="container container--wide flexbox flexbox--sbet">
        <span>Ostatnia aktualizacja: {lastModifiedDate}</span>
        <span>Liczba odsłon: {counter}</span>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  counter: state.layout.counter,
  lastModifiedDate: state.layout.lastModifiedDate,
});

export default connect(mapStateToProps)(Credits);
