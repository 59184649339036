import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoader } from "./Layout.slice";

export default function useLoader(...func: any[]) {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    Promise.all(func.map((f) => dispatch(f()))).then((response) => {
      dispatch(toggleLoader(false));
      setLoaded(true);
    });
  }, []);

  return loaded;
}
