import React from "react";
import { connect } from "react-redux";
import { RootState } from "../store";
import { IHomePage } from "./Home.slice";
import parse from "html-react-parser";

function AboutContract({
  contractDescription,
}: Partial<Pick<IHomePage, "contractDescription">>) {
  return (
    <article className="section">
      {contractDescription && parse(contractDescription)}
    </article>
  );
}

const mapStateToProps = (state: RootState) => ({
  contractDescription: state.home.content?.contractDescription,
});

export default connect(mapStateToProps)(AboutContract);
