import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import parse from "html-react-parser";
import ProgressBar from "../Layout/Progressbar";

export default function WorkProgressPage() {
  // useLoader(fetchWorkProgressAction);

  const content = useSelector(
    (state: RootState) => state.home.workProgressContent
  );
  return (
    <div
      className="banner banner--main"
      style={{
        backgroundImage: `url(${content.banner})`,
      }}
    >
      <div className="container container--narrow banner__content-box progressbar">
        <h1 className="text-white text-center">{content.header}</h1>
        <div className="text-center text-white">
          <h3>{content.hProgressbar?.name}</h3>
          <ProgressBar completed={content.hProgressbar?.value} />
        </div>
        <div className="flexbox flexbox--astart flexbox--wrap flexbox--sbet">
          {content.circualProgressbar?.map((item, index) => (
            <div key={index} className="progressbar__item">
              <CircularProgressbar
                value={item.value}
                text={`${item.value}%`}
                className="progressbar__circle"
                styles={buildStyles({
                  pathColor: `#F5A500`,
                  textColor: "#fff",
                  trailColor: "#fff",
                })}
              />
              <div className="text-center progressbar__label">
                {parse(item.name)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
